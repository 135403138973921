import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.esxi.status');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-esxi-status',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.infra.esxi.status/view.scss */
.card-header {
  background-color: rgba(204, 177, 220, 0.3);
}

.row {
  border-bottom: 1px solid #ddd;
}
.row * {
  line-height: 40px;
}
.row .th {
  font-family: "main-eb";
  text-align: left;
  width: auto;
}
.row .td {
  flex: 1;
  text-align: right;
}
.row .form-control,
.row form-select {
  padding-top: 0;
  padding-bottom: 0;
}`],
})
export class PortalInfraEsxiStatusComponent implements OnInit {
    public list = [];
    public loading = true;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    private loader(act) {
        this.loading = act;
        this.service.render();
    }

    private async load() {
        this.loader(true);
        const { code, data } = await wiz.call("load");
        this.loader(false);
        if (code !== 200) return;
        this.list = data;
        await this.service.render();
    }
}

export default PortalInfraEsxiStatusComponent;