import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.modal.server.info');
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-modal-server-info',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.infra.modal.server.info/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 500px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 500px;
}

.row * {
  line-height: 40px;
}
.row .th {
  width: 100px;
  font-family: "main-eb";
  text-align: left;
}
.row .col-md-4 .th {
  width: 50px;
}
.row .td {
  flex: 1;
  text-align: left;
}
.row .form-control {
  padding-top: 0;
  padding-bottom: 0;
}

textarea {
  resize: none;
}`],
})
export class PortalInfraModalServerInfoComponent implements OnInit {
    @Input() isAdmin = false;
    @Input() info = {};
    @Output() onClose = new EventEmitter();

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default PortalInfraModalServerInfoComponent;