import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.date.picker');
import { OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import datepicker from 'js-datepicker';
import Service from "src/libs/portal/season/service";
const dateOpt = ['ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' }];

@Component({
    selector: 'wiz-component-date-picker',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.date.picker/view.scss */
.date-range > * {
  display: inline-block;
  vertical-align: middle;
}
.date-range .running-checkbox > * {
  display: inline-block;
  vertical-align: middle;
}
.date-range .running-checkbox input {
  width: 20px;
  height: 20px;
}
.date-range .date-start,
.date-range .date-end {
  width: 100%;
  height: 38px;
  margin-top: 1px;
  border: 0;
  border-radius: 7px;
  outline: none;
  text-indent: 40px;
}
.date-range i.fa-xmark,
.date-range .icon-calendar {
  position: absolute;
  top: 0;
  height: 40px;
}
.date-range .icon-calendar {
  left: 0;
  margin: 0 6px;
  width: 28px;
}
.date-range i.fa-xmark {
  right: 0;
  padding: 0 6px;
  width: 34px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
}
.date-range i.fa-xmark:hover {
  transform: scale(1.15);
  text-shadow: 1px 1px 2px var(--wc-blue);
}
.date-range span {
  font-family: "main-eb";
  color: white;
  font-size: 25px;
  line-height: 40px;
}
.date-range .form-control {
  width: 234px;
  height: 40px;
  line-height: 40px;
  border: 0;
  border-radius: 7px;
}
.date-range .btn-search {
  background-color: #6c8df6;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}`],
})
export class ComponentDatePickerComponent implements OnInit, AfterViewInit {
    private isRunning = false;
    private start = null;
    private end = null;
    private text = "";
    @ViewChild("dateEnd") endEl;
    @Input() startClass = "date-start";
    @Input() endClass = "date-end";
    @Input() showText = true;
    @Output() onLoad = new EventEmitter();

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public ngAfterViewInit() {
        this.setDatePicker();
    }

    private load() {
        let data = {
            ...this.start.getRange(),
            isRunning: this.isRunning,
        };
        if (this.showText) data.text = this.text;
        this.onLoad.emit(data);
    }

    private placeholder() {
        return new Date().toLocaleDateString(...dateOpt);
    }

    private setDatePicker() {
        const opt = {
            id: +new Date(),
            formatter: (input, date, instance) => {
                const value = date.toLocaleDateString(...dateOpt);
                input.value = value;
            },
            customMonths: new Array(12).fill(1).map((it, i) => `${it + i}월`),
            customOverlayMonths: new Array(12).fill(1).map((it, i) => `${it + i}월`),
            overlayButton: "이동",
            overlayPlaceholder: `${new Date().getFullYear()}`,
            respectDisabledReadOnly: true,
        };
        this.start = datepicker(`.${this.startClass}`, {
            ...opt,
            onSelect: () => {
                try {
                    setTimeout(() => {
                        this.endEl.nativeElement.focus();
                    }, 100);
                } catch { }
            },
            onHide: (inst) => {
                try {
                    this.start.navigate(inst.dateSelected);
                } catch (err) { }
            },
            maxDate: new Date(),
        });
        this.start.calendarContainer.style.setProperty('font-size', '1.2rem');
        this.end = datepicker(`.${this.endClass}`, {
            ...opt,
            dateSelected: new Date(),
            onHide: (inst) => {
                try {
                    this.end.navigate(inst.dateSelected);
                } catch (err) { }
            },
            maxDate: new Date(),
        });
        this.end.calendarContainer.style.setProperty('font-size', '1.2rem');
    }
}

export default ComponentDatePickerComponent;