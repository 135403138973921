import '@angular/compiler';
import { PortalInfraAdminModalServerRequestComponent } from './portal.infra.admin.modal.server.request/portal.infra.admin.modal.server.request.component';
import { PortalInfraAdminServerRequestComponent } from './portal.infra.admin.server.request/portal.infra.admin.server.request.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalInfraModalServerApplyComponent } from './portal.infra.modal.server.apply/portal.infra.modal.server.apply.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalInfraAdminModalServerAddComponent } from './portal.infra.admin.modal.server.add/portal.infra.admin.modal.server.add.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { ComponentAdminUsersComponent } from './component.admin.users/component.admin.users.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalInfraModalServerInfoComponent } from './portal.infra.modal.server.info/portal.infra.modal.server.info.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { PortalInfraEsxiStatusComponent } from './portal.infra.esxi.status/portal.infra.esxi.status.component';
import { PageServerComponent } from './page.server/page.server.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { ComponentAsideComponent } from './component.aside/component.aside.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalInfraAdminInfoServersComponent } from './portal.infra.admin.info.servers/portal.infra.admin.info.servers.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalInfraServerMyrequestComponent } from './portal.infra.server.myrequest/portal.infra.server.myrequest.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalInfraServerReqlogComponent } from './portal.infra.server.reqlog/portal.infra.server.reqlog.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { ComponentDatePickerComponent } from './component.date.picker/component.date.picker.component';
import { PortalInfraServerListComponent } from './portal.infra.server.list/portal.infra.server.list.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        PortalInfraAdminModalServerRequestComponent,
        PortalInfraAdminServerRequestComponent,
        PageMainComponent,
        PortalInfraModalServerApplyComponent,
        LayoutEmptyComponent,
        PortalInfraAdminModalServerAddComponent,
        LayoutTopnavComponent,
        PortalSeasonViewerTreeComponent,
        ComponentAdminUsersComponent,
        PortalSeasonLoadingHexaComponent,
        PortalInfraModalServerInfoComponent,
        LayoutAsideComponent,
        PortalInfraEsxiStatusComponent,
        PageServerComponent,
        PortalSeasonStatusbarComponent,
        ComponentNavComponent,
        ComponentAsideComponent,
        PortalSeasonAlertComponent,
        PortalSeasonPagenationComponent,
        PortalInfraAdminInfoServersComponent,
        PageAdminComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalInfraServerMyrequestComponent,
        PageLoginComponent,
        PortalSeasonUiDropdownComponent,
        PortalInfraServerReqlogComponent,
        PortalSeasonTabComponent,
        ComponentDatePickerComponent,
        PortalInfraServerListComponent,
        PortalSeasonLoadingComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }