import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.admin.server.request');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-admin-server-request',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.infra.admin.server.request/view.scss */
.card-header {
  background-color: rgba(87, 238, 247, 0.16);
}`],
})
export class PortalInfraAdminServerRequestComponent implements OnInit {
    public list = [];
    public filter = {
        type: "all",
        status: "pending",
    };
    public query: any = {
        current: 1,
        start: 1,
        end: 1,
    };
    public info = null;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    private async load(page = 1) {
        const body = JSON.parse(JSON.stringify(this.filter));
        this.query.current = page;
        body.page = page;

        const { code, data } = await wiz.call("load", body);
        if (code !== 200) return;
        const { lastpage, rows } = data;
        this.list = rows;
        this.query.start = Math.floor((page - 1) / 10) * 10 + 1;
        this.query.end = lastpage;
        await this.service.render();
    }
}

export default PortalInfraAdminServerRequestComponent;