import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.users');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-users',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.admin.users/view.scss */
.container {
  max-width: 1440px;
}

td,
th {
  text-align: left;
}

.question {
  background-color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
}`],
})
export class ComponentAdminUsersComponent implements OnInit {
    public status = "all";
    public query: any = {
        current: 1,
        start: 1,
        end: 1,
    };
    public list = [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/dashboard");
        await this.load();
    }

    public changeStatus(status) {
        this.status = status;
        this.load();
    }

    public async load(page = 1) {
        this.query.current = page;
        const dump = 20;
        const body = {
            status: this.status,
            page,
            dump,
            text: this.query.text,
        };
        const { code, data } = await wiz.call("load", body);
        if (code !== 200) {
            await this.service.error("로드 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        const { lastpage, rows } = data;
        this.list = rows;
        this.query.start = Math.floor((page - 1) / dump) * dump + 1;
        this.query.end = lastpage;
        await this.service.render();
    }

    public async changeAdmin(row) {
        if (this.service.auth.session.id === row.id) {
            await this.service.error("본인의 권한은 수정할 수 없습니다.");
            return;
        }
        const text = row.role === "admin" ? "관리자 -> 유저" : "유저 -> 관리자";
        const res = await this.service.alert.show({
            title: "권한 변경",
            status: "success",
            message: `"${row.name}"의 권한을 ${text} 로 변경하시겠습니까?`,
            actionBtn: "success",
            action: "변경",
            cancel: "취소",
        });
        if (!res) return;
        const { code } = await wiz.call("change_admin", {
            user_id: row.id,
            role: row.role,
        });
        if (code !== 200) {
            await this.service.error("변경 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        await this.service.success(`"${row.name}"의 권한을 변경하였습니다.`);
        await this.load();
    }

    public async accept(row) {
        const res = await this.service.alert.show({
            title: `가입 승인`,
            message: `"${row.name}"의 가입을 허가하시겠습니까?`,
            actionBtn: "success",
            action: "승인",
            cancel: "취소",
        });
        if (!res) return;
        const { code } = await wiz.call("accept", {
            user_id: row.id,
        });
        if (code !== 200) {
            await this.service.error(`허가 실패! 시스템 관리자에게 문의하세요.`);
            return;
        }
        await this.service.success(`"${row.name}"의 가입을 허가하였습니다.`);
        await this.load();
    }

    public async deny(row) {
        const res = await this.service.alert.show({
            title: `가입 거부`,
            message: `"${row.name}"의 가입을 거부하시겠습니까?`,
            action: "거부",
            cancel: "취소",
        });
        if (!res) return;
        const { code } = await wiz.call("deny", {
            user_id: row.id,
        });
        if (code !== 200) {
            await this.service.error("거부 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        await this.service.success(`"${row.name}"의 가입을 거부하였습니다.`);
        await this.load();
    }

    public async pwreset(row) {
        const res = await this.service.alert.show({
            title: `패스워드 초기화`,
            message: `"${row.name}"의 패스워드 초기화 시 "q1w2e3r4!"로 초기화됩니다.`,
            action: "초기화",
            cancel: "취소",
        });
        if (!res) return;
        const password = this.service.auth.hash("q1w2e3r4!");
        const { code } = await wiz.call("pwreset", {
            user_id: row.id,
            password,
        });
        if (code !== 200) {
            await this.service.error("초기화 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        await this.service.success(`"${row.name}"의 패스워드를 초기화하였습니다.`);
        await this.load();
    }

    public copy(token) {
        if (token.length === 0) return;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(token);
        }
        else {
            const textArea = document.createElement('textarea');
            textArea.style.opacity = 0;
            textArea.value = token;
            document.body.appendChild(textArea);
            textArea.select();
            textArea.setSelectionRange(0, 99999);
            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('복사 실패', err);
            }
            textArea.setSelectionRange(0, 0);
            document.body.removeChild(textArea);
        }
        this.service.toast.success("Copied!");
    }
}

export default ComponentAdminUsersComponent;