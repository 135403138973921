import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.loading.hexa');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-portal-season-loading-hexa',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.season.loading.hexa/view.scss */
.socket {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
}
.socket .hex-brick {
  background: #fff;
  width: 30px;
  height: 17px;
  position: absolute;
  top: 5px;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}
.socket .h2 {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}
.socket .h3 {
  transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}
.socket .gel {
  height: 30px;
  width: 30px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
}
.socket .center-gel {
  margin-left: -15px;
  margin-top: -15px;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}
.socket .c1 {
  margin-left: -47px;
  margin-top: -15px;
}
.socket .c2 {
  margin-left: -31px;
  margin-top: -43px;
}
.socket .c3 {
  margin-left: 1px;
  margin-top: -43px;
}
.socket .c4 {
  margin-left: 17px;
  margin-top: -15px;
}
.socket .c5 {
  margin-left: -31px;
  margin-top: 13px;
}
.socket .c6 {
  margin-left: 1px;
  margin-top: 13px;
}
.socket .c7 {
  margin-left: -63px;
  margin-top: -43px;
}
.socket .c8 {
  margin-left: 33px;
  margin-top: -43px;
}
.socket .c9 {
  margin-left: -15px;
  margin-top: 41px;
}
.socket .c10 {
  margin-left: -63px;
  margin-top: 13px;
}
.socket .c11 {
  margin-left: 33px;
  margin-top: 13px;
}
.socket .c12 {
  margin-left: -15px;
  margin-top: -71px;
}
.socket .c13 {
  margin-left: -47px;
  margin-top: -71px;
}
.socket .c14 {
  margin-left: 17px;
  margin-top: -71px;
}
.socket .c15 {
  margin-left: -47px;
  margin-top: 41px;
}
.socket .c16 {
  margin-left: 17px;
  margin-top: 41px;
}
.socket .c17 {
  margin-left: -79px;
  margin-top: -15px;
}
.socket .c18 {
  margin-left: 49px;
  margin-top: -15px;
}
.socket .c19 {
  margin-left: -63px;
  margin-top: -99px;
}
.socket .c20 {
  margin-left: 33px;
  margin-top: -99px;
}
.socket .c21 {
  margin-left: 1px;
  margin-top: -99px;
}
.socket .c22 {
  margin-left: -31px;
  margin-top: -99px;
}
.socket .c23 {
  margin-left: -63px;
  margin-top: 69px;
}
.socket .c24 {
  margin-left: 33px;
  margin-top: 69px;
}
.socket .c25 {
  margin-left: 1px;
  margin-top: 69px;
}
.socket .c26 {
  margin-left: -31px;
  margin-top: 69px;
}
.socket .c27 {
  margin-left: -79px;
  margin-top: -15px;
}
.socket .c28 {
  margin-left: -95px;
  margin-top: -43px;
}
.socket .c29 {
  margin-left: -95px;
  margin-top: 13px;
}
.socket .c30 {
  margin-left: 49px;
  margin-top: 41px;
}
.socket .c31 {
  margin-left: -79px;
  margin-top: -71px;
}
.socket .c32 {
  margin-left: -111px;
  margin-top: -15px;
}
.socket .c33 {
  margin-left: 65px;
  margin-top: -43px;
}
.socket .c34 {
  margin-left: 65px;
  margin-top: 13px;
}
.socket .c35 {
  margin-left: -79px;
  margin-top: 41px;
}
.socket .c36 {
  margin-left: 49px;
  margin-top: -71px;
}
.socket .c37 {
  margin-left: 81px;
  margin-top: -15px;
}
.socket .r1 {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.2s;
}
.socket .r2 {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.4s;
}
.socket .r3 {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.6s;
}
.socket .r1 > .hex-brick {
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.2s;
}
.socket .r2 > .hex-brick {
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.4s;
}
.socket .r3 > .hex-brick {
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.6s;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fade {
  0% {
    background: #ABF8FF;
  }
  50% {
    background: #90BBBF;
  }
  100% {
    background: #ABF8FF;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes fade {
  0% {
    background: #ABF8FF;
  }
  50% {
    background: #389CA6;
  }
  100% {
    background: #ABF8FF;
  }
}`],
})
export class PortalSeasonLoadingHexaComponent implements OnInit {
    constructor() { }

    public async ngOnInit() {
    }
}

export default PortalSeasonLoadingHexaComponent;