import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.login');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-login',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.login/view.scss */
h1 {
  font-size: 42px;
}

.login-view {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}
.login-view::before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url(/assets/images/background.webp);
  opacity: 0.7;
}
.login-view .logo {
  display: flex;
  position: relative;
  align-items: center;
  height: 70px;
  border-radius: 15px;
  padding: 0 32px;
  background-color: rgba(255, 255, 255, 0.6);
}
.login-view .logo * {
  user-select: none;
  -webkit-user-drag: none;
}
.login-view .cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5%;
}
.login-view .cards .card {
  margin: 12px;
  text-align: center;
  width: 100%;
  max-width: 460px;
  display: flex;
}
.login-view .cards .card.wide-card {
  max-width: 800px;
}
.login-view .cards .card.wide-card-xl {
  max-width: 1000px;
}
.login-view .cards .card .card-body {
  padding: 48px;
}
.login-view .cards .card .card-footer {
  background-color: #DEE4F5;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  padding: 12px 72px;
}
.login-view .addition-info {
  margin-top: 24px;
  font-size: 14px;
  display: flex;
}
.login-view .addition-info a {
  color: var(--wc-text);
}
.login-view .addition-info a:hover {
  color: #6380de;
}
.login-view .underline-text-input-group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
  /* reset input */
}
.login-view .underline-text-input-group input {
  line-height: 36px;
}
.login-view .underline-text-input-group .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wc-black);
  font-weight: 700;
}
.login-view .underline-text-input-group .underline-text-input-field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--wc-gray);
  outline: 0;
  font-size: 18px;
  color: #707070;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.login-view .underline-text-input-group .underline-text-input-field::placeholder {
  font-size: 13px;
  color: transparent;
}
.login-view .underline-text-input-group .underline-text-input-field:placeholder-shown ~ .underline-text-input-label {
  font-size: 18px;
  cursor: text;
  top: 20px;
  color: var(--wc-gray);
}
.login-view .underline-text-input-group .underline-text-input-field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-color: var(--wc-blue);
}
.login-view .underline-text-input-group .underline-text-input-field:focus ~ .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wc-blue);
  font-weight: 700;
}
.login-view .underline-text-input-group .underline-text-input-field:required, .login-view .underline-text-input-group .underline-text-input-field:invalid {
  box-shadow: none;
}
.login-view .underline-text-onlyform-input-group {
  position: relative;
  padding: 15px 0 6px 0;
  margin-top: 10px;
  width: 100%;
  font-weight: 700;
  border-width: 3px;
  border-color: var(--wc-gray);
}
.login-view .underline-text-onlyform-input-group input {
  line-height: 36px;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  font-size: 15px;
  color: #000000;
  font-weight: 700;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--wc-gray);
  outline: 0;
  font-size: 18px;
  color: #707070;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field::placeholder {
  font-size: 13px;
  color: var(--wc-gray);
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field:focus {
  padding-bottom: 6px;
  font-weight: 300;
  border-width: 3px;
  border-color: var(--wc-blue);
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field:focus ~ .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wc-blue);
  font-weight: 700;
}
.login-view .error-focus .underline-text-input-field:focus {
  border-color: var(--wc-highlight) !important;
}
.login-view .error-focus .underline-text-input-field:focus ~ .underline-text-input-label {
  color: var(--wc-highlight) !important;
}
.login-view .error-focus .underline-text-input-field:focus::placeholder {
  color: var(--wc-sub-red) !important;
}
.login-view .error-msg {
  color: red;
  font-size: 12px;
}
.login-view .btn {
  padding: 14px 16px;
  border-radius: 10px;
  height: auto;
}

@media (max-width: 768px) {
  .login-view {
    padding: 24px;
    display: block;
  }
  .logo {
    margin-top: 48px;
  }
}
.card.register {
  justify-content: center;
}
.card.register h2 {
  font-size: 32px;
  font-family: SUIT-eb;
}

.btn-grad {
  background-image: linear-gradient(to right, rgba(108, 142, 247, 0.6666666667) 0%, #6c8ef7 51%, rgba(108, 142, 247, 0.6666666667) 100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.card .row .col-md-3 {
  text-transform: uppercase;
  text-align: left;
  line-height: 36px;
}
.card .row .col-md-9 .btn.btn-sm {
  width: 100px;
}
.card .box-sm {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-wrap .content {
  text-align: left;
}

.ck-content {
  padding: 32px !important;
  margin: 0 auto;
  height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #000;
}
.ck-content h2 {
  margin-top: 0 !important;
}

.error input {
  border: 2px solid red;
}`],
})
export class PageLoginComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,    ) { }


    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/dashboard");
    }

    public data: any = {
        email: "",
        password: "",
    };

    public async login() {
        let body = JSON.parse(JSON.stringify(this.data));
        if (!body.email.includes("@")) body.email = `${body.email}@season.co.kr`;
        body.password = this.service.auth.hash(body.password);

        let { code } = await wiz.call("login", body);
        if (code !== 200) return await this.service.error("이메일 또는 패스워드를 다시 확인해주세요.");
        location.href = "/main";
        return;
    }

    public step = 1;

    private init() {
        this.chk = false;
        this.register = {
            email: "",
            name: "",
            hp: "",
            password: "",
            passwordre: "",
        };
    }

    public async move(step) {
        if (this.step === step) return;
        if (this.step === 2 && step === 1) {
            const res = await this.service.alert.show({
                title: "Back",
                message: "정말 되돌아가시겠습니까?",
                action: "back",
                cancel: "cancel",
            });
            if (!res) return;
        }
        this.step = step;
        this.init();
        await this.service.render();
    }

    public register = {
        email: "",
        name: "",
        hp: "",
        password: "",
        passwordre: "",
    };
    public chk = false;

    public async checkDupl() {
        if (!this.validation.email(this.register.email)) {
            return await this.service.error("유효하지 않은 이메일입니다.");
        }
        const { code } = await wiz.call("dupl", { email: this.register.email });
        if (code !== 200) return await this.service.error("유효하지 않은 이메일입니다.");
        this.chk = true;
        await this.service.success("회원가입 가능한 이메일입니다.");
    }

    public error = {
        email: false,
        name: false,
        hp: false,
        password: false,
    };

    public async join() {
        let error = false;
        this.error = {
            email: false,
            name: false,
            hp: false,
            password: false,
        };
        if (!this.validation.email(this.register.email)) {
            error = true;
            this.error.email = true;
        }
        if (!this.validation.name(this.register.name)) {
            error = true;
            this.error.name = true;
        }
        if (!this.validation.hp(this.register.hp)) {
            error = true;
            this.error.hp = true;
        }
        if (this.register.password !== this.register.passwordre) {
            error = true;
            this.error.password = true;
        }
        if (!this.validation.password(this.register.password)) {
            error = true;
            this.error.password = true;
        }

        if (error) return await this.service.error("유효하지 않은 값이 있습니다.");

        const body = JSON.parse(JSON.stringify(this.register));
        delete body.passwordre;
        body.password = this.service.auth.hash(body.password);
        body.agreement = this.agreement.id;

        const { code } = await wiz.call("register", body);
        if (code !== 200) return await this.service.error("가입에 실패하였습니다. 관리자에게 문의하세요.");
        await this.service.success("가입 신청하였습니다. 관리자의 승인을 기다려주세요.");
        this.step = 1;
        this.init(true);
        await this.service.render();
    }

    //----

    private validation = {
        email: (val) => {
            const regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
            return regex.test(val);
        },
        name: (val) => {
            if (val.includes(" ")) return false;
            if (val.length < 2) return false;
            if (val.length > 32) return false;
            const regex = /^[ㄱ-ㅎ가-힣a-zA-Z]+$/;
            return regex.test(val);
        },
        hp: (val) => {
            const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
            return regex.test(val);
        },
        password: (val) => {
            // const regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$/;
            const regex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
            return regex.test(val);
        },
    };
}

export default PageLoginComponent;