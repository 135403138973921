import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.admin.info.servers');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-admin-info-servers',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.infra.admin.info.servers/view.scss */
/* src/app/portal.infra.admin.info.servers/view.scss: no such file or directory */`],
})
export class PortalInfraAdminInfoServersComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,    ) { }

    async ngOnInit() {
        await this.service.init();
    }
}

export default PortalInfraAdminInfoServersComponent;