import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin',
template: templateSource || '',
    styles: [``],
})
export class PageAdminComponent implements OnInit, DoCheck {
    public tab = "info";
    public menu = "users";
    public loaded: boolean = false;

    constructor(@Inject( Service) public service: Service) {
        if (!WizRoute.segment.tab)
            return service.href("/admin/info/users");
        if (!WizRoute.segment.menu)
            return service.href("/admin/info/users");
        this.tab = WizRoute.segment.tab;
        this.menu = WizRoute.segment.menu;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/dashboard");
    }

    public async ngDoCheck() {
        if (!WizRoute.segment.tab)
            return this.service.href("/admin/info/users");
        if (!WizRoute.segment.menu)
            return this.service.href("/admin/info/users");
        this.tab = WizRoute.segment.tab;
        this.menu = WizRoute.segment.menu;
    }

}

export default PageAdminComponent;