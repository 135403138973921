import '@angular/compiler';
import { PortalInfraAdminModalServerRequestComponent } from './portal.infra.admin.modal.server.request/portal.infra.admin.modal.server.request.component';
import { PortalInfraAdminServerRequestComponent } from './portal.infra.admin.server.request/portal.infra.admin.server.request.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalInfraModalServerApplyComponent } from './portal.infra.modal.server.apply/portal.infra.modal.server.apply.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalInfraAdminModalServerAddComponent } from './portal.infra.admin.modal.server.add/portal.infra.admin.modal.server.add.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { ComponentAdminUsersComponent } from './component.admin.users/component.admin.users.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalInfraModalServerInfoComponent } from './portal.infra.modal.server.info/portal.infra.modal.server.info.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { PortalInfraEsxiStatusComponent } from './portal.infra.esxi.status/portal.infra.esxi.status.component';
import { PageServerComponent } from './page.server/page.server.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { ComponentAsideComponent } from './component.aside/component.aside.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalInfraAdminInfoServersComponent } from './portal.infra.admin.info.servers/portal.infra.admin.info.servers.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalInfraServerMyrequestComponent } from './portal.infra.server.myrequest/portal.infra.server.myrequest.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalInfraServerReqlogComponent } from './portal.infra.server.reqlog/portal.infra.server.reqlog.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { ComponentDatePickerComponent } from './component.date.picker/component.date.picker.component';
import { PortalInfraServerListComponent } from './portal.infra.server.list/portal.infra.server.list.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';

const INDEX_PAGE = "dashboard";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutTopnavComponent,
        "children": [
            {
                "path": "dashboard",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "server/form",
                component: PageServerComponent,
                "app_id": "page.server"
            }
        ]
    },
    {
        component: LayoutAsideComponent,
        "children": [
            {
                "path": "admin/:tab?/:menu?",
                component: PageAdminComponent,
                "app_id": "page.admin"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "login",
                component: PageLoginComponent,
                "app_id": "page.login"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }