import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-nav',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.nav/view.scss */
.navbar {
  border-bottom: 1px solid var(--wc-border);
  background: #fff;
}
.navbar .container {
  justify-content: initial;
  padding: 0;
}
.navbar .navbar-brand img {
  height: 64px;
}
.navbar .navbar-toggler {
  color: var(--wc-blue);
  margin-right: 12px;
}
.navbar .nav-link {
  color: var(--wc-text);
  justify-content: center;
  font-size: 18px;
}
@media (min-width: 769px) {
  .navbar .menu-container {
    width: 100%;
  }
  .navbar .menu-container .wiz-top-menu {
    display: flex;
    justify-content: end;
    padding-top: 16px;
  }
  .navbar .menu-container .wiz-top-menu .wiz-menu-item {
    min-width: 64px;
    padding-left: 16px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 14px;
  }
  .navbar .menu-container .wiz-main-menu {
    display: flex;
    justify-content: end;
    padding: 14px 24px;
    padding-bottom: 4px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item {
    min-width: 108px;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 18px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item .nav-link {
    font-weight: 1000;
    font-family: "main-eb";
  }
  .navbar .menu-container .wiz-main-menu .nav-item .active {
    color: var(--wc-blue);
  }
}
.navbar a {
  color: var(--wc-text);
  cursor: pointer;
}
@media (max-width: 768px) {
  .navbar .navbar-brand img {
    height: 32px;
  }
  .navbar .wiz-top-menu {
    padding-top: 0;
  }
}

.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
}
.wiz-modal .modal-content {
  padding: 20px;
  width: 600px;
  overflow-y: auto;
  background-color: #EAF0FB;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}`],
})
export class ComponentNavComponent implements OnInit {
    public isMenuCollapsed: boolean = true;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public show = false;
    public data = {
        old: "",
        password: "",
        passwordRepeat: "",
    };
    public showModal() {
        this.show = true;
        this.service.render();
    }
    public hideModal() {
        this.show = false;
        this.service.render();
    }

    public async chagePassword() {
        // const regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$/;
        const regex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
        if (!regex.test(this.data.password)) {
            return await this.service.error("유효하지 않은 패스워드입니다.");
        }
        if (this.data.password !== this.data.passwordRepeat) {
            return await this.service.error("유효하지 않은 패스워드입니다.");
        }
        const body = {
            old: this.service.auth.hash(this.data.old),
            password: this.service.auth.hash(this.data.password),
        };
        const { code } = await wiz.call("change_password", body);
        if (code !== 200) {
            return await this.service.error("기존 패스워드가 일치하지 않습니다.");
        }
        await this.service.success("패스워드를 성공적으로 변경하였습니다. 다시 로그인해주세요.");
        location.href = "/auth/logout";
    }
}

export default ComponentNavComponent;