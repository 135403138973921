import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.server.reqlog');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-server-reqlog',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.infra.server.reqlog/view.scss */
.card-header {
  background-color: rgba(186, 243, 216, 0.26);
}`],
})
export class PortalInfraServerReqlogComponent implements OnInit {
    public list = [];
    public filter = {
        type: "all",
        status: "all",
    };
    public info = null;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    private async load() {
        const { code, data } = await wiz.call("load", this.filter);
        if (code !== 200) return;
        this.list = data;
        await this.service.render();
    }
}

export default PortalInfraServerReqlogComponent;